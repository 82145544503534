
import {
    defineComponent,
    reactive,
    toRefs,
    ref,
    onBeforeMount,
    getCurrentInstance,
    ComponentInternalInstance,
    createVNode,
    watch
} from "vue";
import { TreeDataItem } from 'ant-design-vue/es/tree/Tree';
import pagaTable from "@/components/pagination-table/index";
import { TransformCellTextProps } from "ant-design-vue/lib/table/interface";
import formSearch from "@/components/page-search/form";
import popupWindow from "./popupNew.vue";
import { rolePage, role_del, menuTree, roleAssign_post,roleIdAPI } from '@/api/system';
const purchaseColumns = [
    {
        title: "序号",
        width: 60,
        dataIndex: "index",
        customRender: (row: TransformCellTextProps) => row.index + 1,
    },
    {
        title: "角色名称 ",
        dataIndex: "roleName",
    },
    {
        title: "角色描述",
        dataIndex: "description",
    },
    {
        title: "创建人",
        dataIndex: "createdBy",
    },
    {
        title: "创建时间",
        dataIndex: "createdTime",
    },
    {
        title: "操作",
        dataIndex: "operation",
        slots: { customRender: "operation" },
    },
];
const replaceFields = {
    children: 'children',
    title: 'menuName',
    key: 'menuId'
};
export default defineComponent({
    name: "questionType",
    components: {
        pagaTable,
        popupWindow,
        formSearch,
    },
    setup(props: any) {
        const { proxy } = getCurrentInstance() as ComponentInternalInstance;
        const treeData = ref<TreeDataItem[]>([]);
        const data = reactive({
            purchaseColumns,
            selectedRowKeys: [],
            extraModel: {},
            formBtn: {
                colProps: { span: 16 },
                formItemProps: {
                    wrapperCol: {
                        span: 24,
                    },
                },
            },
        });
        const onSelectChange = (selectedRowKeys: never[]) => {
            data.selectedRowKeys = selectedRowKeys;
        };
        const table = ref();
        const getDataApi = (params: any) => {
            return new Promise((reslove) => {
                const params_api = {
                    "currentPage": params.pageIndex,
                    "pageSize": params.pageSize,
                }
                rolePage(params_api).then(res => {
                    reslove(res)
                })
            });
        };
        const handleChange = (
            pagination: any,
            filter: any,
            sorter: any,
            { currentDataSource }: any
        ) => {
            console.log(pagination, filter, sorter, currentDataSource);
        };
        const handleClick = (text: string, row: any) => {
            Title.value = "编辑角色";
            popupWindow.value.showModal(row);
        };
        const formSearch = ref();
        const popupWindow = ref();
        const popupRandom = ref();
        const Title = ref();
        const updatedTime = ref([]);
        const handleAdd = () => {
            Title.value = "新增角色";
            popupWindow.value.showModal();
        };
        const handleSearch = (formState: any) => {
            // formSearch.value.getQuery();
            // console.log({ ...formSearch.value.getQuery() });
            // console.log("插槽", { ...updatedTime.value });
            table.value.refresh();
        };
        const refresh = () => {
            table.value.refresh();
            data.selectedRowKeys = []
        }

        const visible = ref<boolean>(false);
        const questions = ref([] as any[])
        const del = (val: any) => {
            role_del({ id: val.roleId }).then(res => {
                table.value.refresh();
            })
        };
        const roleId = ref()
        const checkedKeys = ref<{ checked?: string[] | number[], halfChecked?: string[] | number[] }>({});

        const authorization = (val: any) => {
            console.log(val.roleId);
            
            visible.value = true
            treeData.value = []
            roleId.value = val.roleId
            menuTree({}).then(res => {
                treeData.value = res.data
            })
            const menus: any[]=[]
            roleIdAPI(val.roleId).then((res:any)=>{
                console.log(res.data.menus);
                res.data.menus.map((res: any)=>{
                    menus.push(res.menuId)
                })
                checkedKeys.value.checked = menus
            })
        };
        const handleOk = () => {
            roleAssign_post({ menusIds: checkedKeys.value?.checked, roleId: roleId.value }).then(res => {
                visible.value = false
            })
        };
        return {
            handleOk,
            replaceFields,
            treeData,
            checkedKeys,
            authorization,
            questions,
            visible,
            del,
            refresh,
            ...toRefs(data),
            table,
            onSelectChange,
            getDataApi,
            handleChange,
            handleClick,
            formSearch,
            popupWindow,
            popupRandom,
            Title,
            handleAdd,
            handleSearch,
            updatedTime,
        };
    },
});
